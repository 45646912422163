<i18n src="@/locales/prosthesis.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="prothesis">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <span v-html="$t('message_1')" />
    </b-message>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_1') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_2_1') }}
        </p>
        <p>
          {{ $t('article_2_2') }}
        </p>
        <ImanH3>{{ $t('h3_title_3') }}</ImanH3>
        <p>
          {{ $t('article_3_1') }}
        </p>
        <p>
          <span v-html="$t('article_3_2')" />
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>{{ $t('h3_title_4') }}</ImanH3>
        <p>
          {{ $t('article_4') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[3]">
        <ImanH3>{{ $t('h3_title_5') }}</ImanH3>
        <p>
          {{ $t('article_5') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard :title="$t('collapse_title_1')">
          <p>
            {{ $t('collapse_p2_1') }}
          </p>
          <p>
            {{ $t('collapse_p2_2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_2')">
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_3')">
          <p>
            {{ $t('collapse_p4') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_4')">
          <p>
            {{ $t('collapse_p5_1') }}
          </p>
          <p>
            {{ $t('collapse_p5_2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_5')">
          <p>
            {{ $t('collapse_p6') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_6')">
          <p>
            {{ $t('collapse_p7') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_7')">
          <p>
            {{ $t('collapse_p8') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_8')">
          <p>
            {{ $t('collapse_p9') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_9')">
          <p>
            {{ $t('collapse_p10_1') }}
          </p>
          <p>
            {{ $t('collapse_p10_2') }}
          </p>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'Prosthesis',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        articlesCardContentId: [83, 222, 46, 85],
        img: [
          {
            path: 'dental-implant/basal-implant/prothesis/prothese-dentaire-fixe-definition.jpg',
            position: 'right',
            alt: 'prothèse dentaire fixe'
          },
          {
            path: 'dental-implant/basal-implant/prothesis/fabrication-prothese-dentaire.jpg',
            position: 'right',
            alt: 'matériaux d\'une prothèse fixe sur implant basal'
          },
          {
            path: 'dental-implant/basal-implant/prothesis/fixation-prothese-sur-implants.jpg',
            position: 'right',
            alt: 'mise en charge immédiate'
          },
          {
            path: 'dental-implant/basal-implant/prothesis/occlusion-prothese-dentaire-fixe.jpg',
            position: 'right',
            alt: 'occlusion dentaire avec une prothèse fixe'

          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.1.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/prothesis/H1-prothese-dentaire-fixe-implant-basal-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/prothesis/H1-prothese-dentaire-fixe-implant-basal-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/prothesis/H1-prothese-dentaire-fixe-implant-basal-m.jpg");
    }
  }
</style>
